import React, { Component } from 'react'
import { Link } from "gatsby"

import Main from '../layouts/main'
import Hero from '../components/hero'

import styles from './services.module.scss'

class WhatWeDo extends Component {

  render(){
    return (
      <Main>
        <Hero
          backgroundImage='milkyWayBackground'
        >
          <div className={`${styles.container} ${styles.heroLarge}`}>
            <div className={styles.hero}>
              <h1 className={`${styles.fadeInOne} ${styles.titleHero}`}>
                SOFTWARE & ANALYTICS
              </h1>
              <hr className={styles.divider}/>
              <h1 className={`${styles.fadeInTwo} ${styles.titleHero}`}>
                INTEGRATED
              </h1>
            </div>
          </div>
        </Hero>
        <div className={styles.gridAlpha}>
          <div className={styles.colSmall}>
            <h3 className={`${styles.homeh3} ${styles.fadeInOne}`}>
              The intersection of <br/>engineering & analytics
            </h3>
            <hr className={styles.homeHr}/>
          </div>
          <div className={styles.colLarge}>
            <p className={styles.homeP}>
              Elementive works at the intersection of engineering and analytics.
              We believe in honesty, a sense of utility, and are committed to progress over pride.
            </p>
            <p className={styles.homeP}>
              Launching a new product? Need Help with an existing project?
            </p>
            <Link to="/contact/">
              <button className={`${styles.button} ${styles.buttonMargin}`}>
                Let's Build Something
              </button>
            </Link>
          </div>
        </div>
        <div className={`${styles.backgroundCloud} ${styles.hasTextWhite}`}>
          <div className={styles.gridCapabilities}>
            <div className={styles.capabilitesTitle}>
              <h2 className={styles.howWeTitle}>
                Capabilities
              </h2>
            </div>
            <div className={styles.engineering}>
              <h3>
                Engineering
              </h3>
              <hr className={styles.homeHr}/>
              <p>
                Modern UI's and battle hardy backends. Our commitment to full-stack engineering acts to reduce dependencies and eliminate hoops for you to jump through - we'll ensure that you don't have to go through the ringer finding multiple developers to get the job done.
                We believe that development speed, automation and testing are key to good software, which is why we put emphasis on solid CI/CD practices, modern development environments and robust testing frameworks.
              </p>
            </div>
            <div className={styles.engineeringList}>
              <ul className={styles.capabilitiesList}>
                <li>
                  Fullstack 
                </li>
                <li>
                  Web & Mobile
                </li>
                <li>
                  Python & Javascript
                </li>
                <li>
                  Cloud Architecture
                </li>
                <li>
                  Platform Integrations
                </li>
                <li>
                  UI/UX
                </li>
                <li>
                  Devops
                </li>
              </ul>
            </div>
            <div className={styles.analytics}>
              <h3>
                Analytics
              </h3>
              <hr className={styles.homeHr}/>
              <p>
                Whether you need to develop an ETL pipeline, predictive model, visualization dashboard or search engine we have your back.
                Where many analytics consultancy stop at the analysis we don't just identify the solutions and improvements but help you implement them.
                The end result of an analysis wont be a single page document but a fully functioning application or API to suit your needs.
                We believe data science is the last 20% of any analytics problem and are committed to the integration with engineering.
              </p>
            </div>
            <div className={styles.analyticsList}>
              <ul className={styles.capabilitiesList}>
                <li>
                  Machine Learning
                </li>
                <li>
                  Natural Language
                </li>
                <li>
                  Computer Vision
                </li>
                <li>
                  Data Pipelines
                </li>
                <li>
                  Reporting
                </li>
                <li>
                  Charting & Visualization
                </li>
                <li>
                  Search
                </li>
              </ul>
            </div>
            <div className={styles.stategy}>
              <h3>
                Strategy
              </h3>
              <hr className={styles.homeHr}/>
              <p>
                Elementive offers project leadership and technical expertise. We will audit your product,
                help identify areas for improvement and select the technologies best suited to help you reach your business and technology goals.
                We won’t just be an extra set of hands, we'll help guide your strategy and enact best practices to ensure a maintainable and sustainable product.
                Whether you have an internal team or not we are ready to adapt to suit the needs of your project.
              </p>
            </div>
            <div className={`${styles.stategyList}`}>
              <ul className={styles.capabilitiesList}>
                <li>
                  Architecture Design
                </li>
                <li>
                  Technology Selection
                </li>
                <li>
                  Development Process
                </li>
                <li>
                  Product Management
                </li>
                <li>
                  Third Party Tools
                </li>
                <li>
                  Technical Hiring
                </li>
                <li>
                  Research
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Main>
    )
  }
}

export default WhatWeDo
